













































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import {
  Configuration,
  RedCapital,
  SelectedCountry,
  TitleTemplatePublic
} from "@/configuration";
import html2canvas from 'html2canvas';
import API from "@/api";
import { EventBus } from "@/main";
import CurrencyInput from "@/components/Currency.vue";
import DniPerson from "@/components/DniField.vue";
import DniBusiness from "@/components/DniFieldBusiness.vue";
import { isRutValid } from "@/utils";
import { mapState } from "vuex";
import Axios from "axios";

@Component({

    data() {
      return {
        e1: 1,
        check:null,
        numeroactividad:0,
        siguiente:true,
        dialog:false,
        dialog2:false,
        dialog3:false,
        dialog4:false,
        actividades:[11,12,13,14],
            respuesta:[],
            realizados1:[],
            r1:[11,12,13,14],
            result1:[],
        FormOne:{
            answers:[
                {
                user_id: this.$store.state.user.id,
                cta_answer_id:"111",
                respuesta:null
                },{
                user_id: this.$store.state.user.id,
                cta_answer_id:"112",
                respuesta:null
                },{
                user_id: this.$store.state.user.id,
                cta_answer_id:"113",
                respuesta:null
                },{
                user_id: this.$store.state.user.id,
                cta_answer_id:"114",
                respuesta: null
                }
            ],
        },
        FormTwo:{
            answers:[
                {
                user_id: this.$store.state.user.id,
                cta_answer_id:null,
                respuesta:null
                }
            ]
        },
        FormThree:{
             answers:[
                 {
                user_id: this.$store.state.user.id,
                cta_answer_id:"131",
                respuesta:null
                 }
            ]
        },
        FormFour:{
            answers:[
                {
                    user_id: this.$store.state.user.id,
                    cta_answer_id:[],
                    respuesta:null
                }
            ]
        },
        rules: {
            notEmpty: [
                (v: any) => !!v || "Ingrese un valor",
                (v: any) => v.length > 3 || "Minimo 4 caracteres"
                ],
            phone: [
            (v: any) => !(/[^\d]/gim.test(v)) || 'Tiene que ser solo numeros',
            (v: any) => !!v || 'Teléfono es requerido',
            ],
            }
        }
    },watch:{
        "check"(v:any){
            if(v==121){
               let element = document.getElementsByClassName('check1')[0]
               //@ts-ignore
               element.classList.remove("selection");
               let element2 = document.getElementsByClassName('check2')[0]
               //@ts-ignore
               element2.classList.add("selection");
            }else if(v==122){
            let element = document.getElementsByClassName('check2')[0]
               //@ts-ignore
               element.classList.remove("selection");
               let element2 = document.getElementsByClassName('check1')[0]
               //@ts-ignore
               element2.classList.add("selection");
            }
        }
    }
    ,methods:{
        cerrarmodal(modal){
            if(modal == 1){
                this.$data.dialog1 = false
            }else if(modal == 2){
                this.$data.dialog2 = false
            }
            else if(modal == 3){
                this.$data.dialog3 = false
            }
            else if(modal == 4){
                this.$data.dialog4 = false
            }
        },
        verbeneficios(numbeneficio){
            this.$data.dialog4 = true 
        },
        vermodalmembresias(numeromodalmembresia){
            this.$data.dialog3 = true
        },
        gosabermas(){
            this.$router.push({
                path: "/actividad/sabermas"
            })
        },
        guardarlistado(){
                API.actividad.organiza.guardarlistado(this.$data.FormOne)
                    .then((resolve)=>{
                        API.registraranalytics('CTA',this.$data.ip,'11',this.$store.state.user.id)
                            .then((res: any) => {
                                console.log('datos registrados');
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                         //@ts-ignore
                        this.obtenerdatos()
                        .then((res:any) =>{
                            this.$data.numeroactividad = 1;
                            EventBus.$emit("snack-success", "Datos registrados correctamente");
                            if(res.length == 4){
                                this.$data.dialog2 = true
                            }else{
                                   if(res.indexOf(12) > -1){
                                        this.$data.siguiente=false;
                                        this.$data.dialog = true
                                    }else{
                                        this.$data.siguiente=true;
                                        this.$data.dialog = true
                                    }
                            }
                        })
                    })
                    .catch((error) =>{
                        console.log(error)
                    })

        },
        listadosituacion(){
            this.$data.FormTwo.answers[0].cta_answer_id = this.$data.check;
            API.actividad.organiza.listadosituacion(this.$data.FormTwo)
            .then((resolve)=>{
                API.registraranalytics('CTA',this.$data.ip,'12',this.$store.state.user.id)
                            .then((res: any) => {
                                console.log('datos registrados');
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                console.log('res',resolve)
                    //@ts-ignore
                    this.obtenerdatos()
                    .then((res:any) =>{
                        this.$data.numeroactividad = 2;
                        EventBus.$emit("snack-success", "Datos registrados correctamente");
                        if(res.length == 4){
                            this.$data.dialog2 = true
                        }else{
                                if(res.indexOf(13) > -1){
                                    this.$data.siguiente=false;
                                    this.$data.dialog = true
                                }else{
                                    this.$data.siguiente=true;
                                    this.$data.dialog = true
                                }
                        }
                    })
            })
            .catch(error => console.log(error))
        },
        guardarfirma(){
            var canvas2 = document.getElementById('pizarra');
             //@ts-ignore
            var dataURL = canvas2.toDataURL("image/png");
            console.log(dataURL)
             //@ts-ignore
            this.$data.FormThree.answers[0].respuesta = dataURL;
            Axios.post(`${Configuration.api.base}/cta/cta13`, {
                "user_id" : this.$data.FormThree.answers[0].user_id,
                "cta_answer_id" : this.$data.FormThree.answers[0].cta_answer_id,
                "respuesta": this.$data.FormThree.answers[0].respuesta
            }, {
                headers: {
                Authorization: `Bearer ${this.$store.state.token}`
                }
            }).then(res=>{
                API.registraranalytics('CTA',this.$data.ip,'13',this.$store.state.user.id)
                            .then((res: any) => {
                                console.log('datos registrados');
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                console.log(res)
                //@ts-ignore
                this.obtenerdatos()
                    .then((res:any) =>{
                        this.$data.numeroactividad = 3;
                        EventBus.$emit("snack-success", "Datos registrados correctamente");
                        if(res.length == 4){
                            this.$data.dialog2 = true
                        }else{
                               if(res.indexOf(14) > -1){
                                    this.$data.siguiente=false;
                                    this.$data.dialog = true
                                }else{
                                    this.$data.siguiente=true;
                                    this.$data.dialog = true
                                }
                        }
                    })
            }).catch(error => {
                console.log(error)
            })

        },
       obtenerdatos(){
           return new Promise((resol,reje)=>{
               API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
                .then((resolve:any)=>{
                    for(let i=0; i < this.$data.actividades.length ;i++){
                        for(let j=0; j < resolve.data.data.length ;j++){
                            if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                                if(this.$data.actividades[i] < 15){
                                    this.$data.realizados1.push(this.$data.actividades[i])
                                }
                            }
                        }
                    }
                    resol(this.$data.realizados1 = Array.from(new Set(this.$data.realizados1)))      
                }).catch(error => console.log(error))
           })
        },
        guardarfinanzas(){
            this.$data.FormFour.answers[0].cta_answer_id.forEach((elem: any, index: number) => {
            this.$data.FormFour.answers[0].cta_answer_id = parseInt(elem)
            })
            API.actividad.organiza.guardarfinanzas(this.$data.FormFour)
            .then((resolve)=>{
                API.registraranalytics('CTA',this.$data.ip,'14',this.$store.state.user.id)
                            .then((res: any) => {
                                console.log('datos registrados');
                            })
                            .catch((err: any) => {
                                console.log(err);
                            });
                //@ts-ignore
                this.obtenerdatos()
                .then((res:any) =>{
                    this.$data.numeroactividad = 4;
                     EventBus.$emit("snack-success", "Datos registrados correctamente");
                    if(res.length == 4){
                        this.$data.dialog2 = true
                    }else{
                            if(res.indexOf(14) > -1){
                                this.$data.siguiente=false;
                                this.$data.dialog = true
                            }else{
                                this.$data.siguiente=true;
                                this.$data.dialog = true
                            }
                    }
                })               
            })
            .catch(error => console.log(error))
        },
        godashboard(){
            this.$router.push({
                path: "/actividad/admin-user"
            })
        },
        next(){
            
            setTimeout(()=>{
                    this.$data.dialog = false
                    this.$data.e1 += 1;
                },1000)
            
        }
    },async beforeMount(){
        API.actividad.infoactividades.actividadesrealizadas(this.$store.state.user.id)
                .then((resolve:any)=>{
                    for(let i=0; i < this.$data.actividades.length ;i++){
                        for(let j=0; j < resolve.data.data.length ;j++){
                            if(this.$data.actividades[i]===resolve.data.data[j].cta_list_id){
                                if(this.$data.actividades[i] < 15){
                                    this.$data.realizados1.push(this.$data.actividades[i])
                                }
                            }
                        }
                    }
                    this.$data.realizados1 = Array.from(new Set(this.$data.realizados1))
                }).catch(error => console.log(error))

        let etapa = this.$route.params.etapa;
        if (this.$route.params.etapa != null){
            this.$data.e1 = this.$route.params.etapa
            fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
                .then(({ ip }) => {
                    this.$data.ip=ip;
                    API.registraranalytics('Actividad organiza',this.$data.ip,`Ingresó desde el dashboard ${this.$route.params.etapa}`,this.$store.state.user.id)
                    .then((res: any) => {
                        console.log(`Ingresó directo por url ${this.$route.params.etapa}`);
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            });
        }else{
            fetch('https://api.ipify.org?format=json')
            .then(x => x.json())
                .then(({ ip }) => {
                    this.$data.ip=ip;
                    API.registraranalytics('Actividad organiza',this.$data.ip,'Entro a la vista',this.$store.state.user.id)
                .then((res: any) => {
                    console.log('Entro a la vista');
                })
                .catch((err: any) => {
                    console.log(err);
                });
            });
        }
        
    },
    mounted(){
    const canvas = document.querySelector('#pizarra');
    //@ts-ignore
    const ctx = canvas.getContext('2d');
    //@ts-ignore
    canvas.width = 500;
    //@ts-ignore
    canvas.height = 200;

    ctx.strokeStyle = '#000';
    ctx.lineJoin = 'round';
    ctx.lineCap = 'round';
    ctx.lineWidth = 3;
    ctx.globalCompositeOperation = 'multiply';

    let isDrawing = false;
    let lastX = 0;
    let lastY = 0;
    let hue = 0;
    let direction = true;

    function draw(e:any) {
        if (!isDrawing) return; //Stop the function if the user has not pressed left mouse button.
        //ctx.strokeStyle = `hsl(${hue}, 100%, 50%)`;
        ctx.beginPath();
        ctx.moveTo(lastX, lastY);
        ctx.lineTo(e.offsetX, e.offsetY);
        ctx.stroke();
        [lastX, lastY] = [e.offsetX, e.offsetY];
        hue++;
        if (hue >= 360) {
            hue = 0;
        }
       /* if (ctx.lineWidth >= 100 || ctx.lineWidth <= 1) {
            console.log(direction);
            direction = !direction;
        }
        if (direction) {
            ctx.lineWidth++;
        } else {
            ctx.lineWidth--;
        }
*/
    }

    window.addEventListener('mousedown', (e) => {
        isDrawing = true;
        [lastX, lastY] = [e.offsetX, e.offsetY];
    });
    window.addEventListener('mousemove', draw);
    window.addEventListener('mouseup', () => isDrawing = false);
    window.addEventListener('mouseout', () => isDrawing = false);
    }
})
export default class OrganizaTusFinanzas extends Vue {}
